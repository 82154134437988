import banner from './banner_1-1-0';
import banner114 from './banner_1-1-4';
import contentArticle from './content-article_1-1-0';
import contentMedia from './content-media_1-1-0';
import contentSport from './content-sport_1-1-0';
import error from './error_1-1-0';
import list from './list_1-1-0';
import listElement from './listelement_1-1-0';
import mediaQoe from './mediaqoe_1-1-0';
import mediaSession from './mediasession_1-1-0';
import page from './page_1-1-0';
import player from './player_1-1-0';
import feature from './feature_1-1-0';
import target from './target_1-1-0';
import search from './search_1-1-2';

export default {
    context_article: contentArticle,
    context_banner: banner,
    context_banner_1_1_4: banner114,
    context_content_article: contentArticle,
    context_content_media: contentMedia,
    context_content_sport: contentSport,
    context_error: error,
    context_list: list,
    context_list_element: listElement,
    context_media_qoe: mediaQoe,
    context_media_session: mediaSession,
    context_page: page,
    context_player: player,
    context_feature: feature,
    context_target: target,
    context_search: search,
};
