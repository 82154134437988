import pageTemplates from './page';
import bannerTemplates from './banner';
import contextTemplates from './contexts/index';
import listTemplates from './list';
import mediaTemplates from './media';

export default {
    ...pageTemplates,
    ...bannerTemplates,
    ...contextTemplates,
    ...listTemplates,
    ...mediaTemplates,
};
