import bannerContextTemplate from './contexts/banner_1-1-0';
import bannerContext114Template from './contexts/banner_1-1-4';
import featureContextTemplate from './contexts/feature_1-1-0';

const templates = {};

templates.banner_impression_1_1_0 = {
    event: {
        schema: 'iglu:be.vrt/banner_impression/jsonschema/1-1-0',
        data: {
            timestamp: '$now()',
        },
    },
    context: [
        bannerContextTemplate,
        featureContextTemplate,
    ],
};

templates.banner_impression_1_1_4 = {
    event: {
        schema: 'iglu:be.vrt/banner_impression/jsonschema/1-1-4',
        data: {
            timestamp: '$now()',
        },
    },
    context: [
        bannerContext114Template,
        featureContextTemplate,
    ],
};

templates.banner_click_1_1_0 = {
    event: {
        schema: 'iglu:be.vrt/banner_click/jsonschema/1-1-0',
        data: {
            timestamp: '$now()',
        },
    },
    context: [
        bannerContextTemplate,
        featureContextTemplate,
    ],
};

templates.banner_click_1_1_4 = {
    event: {
        schema: 'iglu:be.vrt/banner_click/jsonschema/1-1-4',
        data: {
            timestamp: '$now()',
        },
    },
    context: [
        bannerContext114Template,
        featureContextTemplate,
    ],
};

export default templates;
